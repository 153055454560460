export default function GigEntry(props) {
    return(
        <div className="flex flex-row justify-between items-center w-full">
            <div>
                <h1 className="font-Cinzel text-2xl">
                    {props.title}
                </h1>
                <h2 className="font-Cinzel text-xl">
                        {props.date}
                </h2>
            </div>
            <button className="py-2 px-4 bg-gray-500 rounded-md text-white ml-6 cursor-not-allowed">
                Coming Soon
            </button>
        </div>
    )
}