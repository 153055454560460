import GigEntry from "./GigEntry";

const data = {
    content: {
       gigs: [
           {
               title: "Metal 2 The Masses",
               date: "7th February 2025",      
           },
       ],
    }
}   

export default function Home() {
    return(
        <div className="flex flex-col justify-center items-center w-screen overflow-hidden scroll-smooth">
            <div className="relative">
                <video autoPlay muted loop src="./rotherhamlive.mp4" type="video/mp4" className="h-screen w-screen object-cover"/>
                <div className="absolute h-screen w-screen flex flex-col items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm px-4 top-0">
                    <img src="./logowhite.png" className="h-[400px] lg:h-[600px] object-cover"/>
                    <h1 className="text-2xl max-w-[600px] w-full text-center font-Cinzel py-6">SHEFFIELD TECHNICAL DEATH METAL BAND</h1>
                    <a href="#gigs" className="bg-white py-2 px-6 rounded-md text-black font-Cinzel font-xl hover:bg-red-700 hover:text-white duration-300 cursor-pointer">
                        UPCOMING GIGS
                    </a>
                </div>
            </div>
            <div className="flex flex-col items-center py-8 w-full px-4 pt-20">
                <div className="flex flex-col items-center max-w-[1200px]" id="bio">
                    <h1 className="font-Cinzel text-4xl font-xl text-center">
                        CAST DOWN TO THE VOID WHERE THEY WAIT FOR ME
                    </h1>
                    <hr className="h-[2px] bg-white w-1/2 my-4"/>
                    <p className="text-center font-Nanum">
                        Dominion of Ashes is a metal band hailing from Sheffield, England, known for their dark 
                        and aggressive sound that blends elements of extreme metal genres. Drawing inspiration 
                        from the heavy, industrial roots of their hometown, Dominion of Ashes merges crushing 
                        guitar riffs, thundering drums, and intense vocals to create a powerful sonic landscape. 
                        The band’s lyrics often explore themes of existential despair, societal decay, and inner 
                        turmoil, all conveyed through raw energy and an unmistakable intensity.
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row lg:py-[200px] py-[150px] w-full max-w-[1200px]" id="merch">
                    <div className="relative lg:w-1/2 flex flex-col lg:flex-row items-center">
                        <img src="./shirt1.jpg" className="top-0 lg:w-1/2 hidden lg:block"/>
                        <img src="./shirt2.jpg" className="lg:absolute top-0 right-20 h-full"/>
                    </div>
                    <div className="lg:w-1/2 flex flex-col items-center pt-8">
                        <h1 className="font-Cinzel text-4xl font-xl text-center">
                            Official Merchandise
                        </h1>
                        <hr className="h-[2px] bg-white w-1/2 my-4"/>
                        <p className="font-nanum text-center">
                            Visit the official Dominion of Ashes merchandise store on Bandcamp
                            for T-shirts, patches and more
                        </p>
                        <a href="https://dominionofashes.bandcamp.com/merch" target="_blank" className="font-Cinzel py-4 px-8 bg-white text-2xl text-black rounded-md my-6 hover:bg-red-700 hover:text-white duration-300 cursor-pointer">
                            Merch Store
                        </a>
                    </div>
                </div>
                <div className="w-full relative flex flex-col justify-center items-center">
                    <video autoPlay muted loop src="./rotherhamlive.mp4" type="video/mp4" className="h-[400px] object-cover w-full"/>
                    <div className="flex flex-col items-center absolute w-full max-w-[1200px] bg-black p-4 bg-opacity-25 backdrop-blur-md rounded-md">
                        <h1 className="font-Cinzel text-4xl font-xl text-center">
                            RISE FROM THE ASHES
                        </h1>
                        <hr className="h-[2px] bg-white w-1/2 my-4"/>
                        <p className="text-center font-Nanum">
                            Formed in 2018, Dominion of Ashes quickly gained a reputation in the local 
                            metal scene for their explosive live shows and uncompromising style. With influences 
                            ranging from classic death and black metal to modern metalcore, their sound is both brutal 
                            and atmospheric, appealing to fans of both old-school heaviness and contemporary metal innovation.
                            As they continue to push the boundaries of their sound, Dominion of Ashes remains a formidable 
                            force in Sheffield's metal community, embodying the gritty, industrial spirit of the city 
                            while forging their own distinct path in the global metal landscape.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col items-center my-[100px] py-4 w-full max-w-[800px] px-4 bg-stone-900 rounded-md" id="gigs">
                    <h1 className="font-Cinzel text-4xl font-xl">
                        Upcoming Gigs
                    </h1>
                    <hr className="h-[2px] bg-white w-1/2 my-2"/>
                    <GigEntry title="Metal 2 The Masses" date="7th February 2025"/>
                </div>
                <div className="w-full flex flex-col items-center max-w-[1200px]" id="wretched">
                    <h1 className="font-Cinzel text-4xl font-xl text-center">
                        THIS WRETCHED HUMAN
                    </h1>
                    <hr className="h-[2px] bg-white w-1/2 my-4"/>
                    <p className="text-center font-Nanum">
                        Dominion of Ashes released their debut EP, Wretched, in 2022, showcasing 
                        their ability to craft complex and relentless tracks that capture the raw emotion and energy 
                        of their live performances. Known for their commitment to authenticity and an unyielding DIY 
                        ethic, the band has carved out a dedicated fan base in the underground metal scene, sharing 
                        stages with prominent local and international acts.
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row py-[100px] w-full max-w-[1200px]">
                    <div className="relative lg:w-1/2 flex flex-col lg:flex-row items-center justify-center lg:hidden">
                        <img src="./wretched.jpg" className="h-[300px]"/>
                    </div>
                    <div className="lg:w-1/2 flex flex-col items-center pt-8">
                        <h1 className="font-Cinzel text-4xl font-xl text-center">
                            Wretched EP
                        </h1>
                        <hr className="h-[2px] bg-white w-1/2 my-4"/>
                        <p className="font-nanum text-center">
                            Physical and digital copies are available on the official Dominion of Ashes Bandcamp store.
                        </p>
                        <a href="https://dominionofashes.bandcamp.com/album/wretched-ep" target="_blank" className="font-Cinzel py-4 px-8 bg-white text-2xl text-black rounded-md my-6 hover:bg-red-700 hover:text-white duration-300 cursor-pointer">
                            Bandcamp
                        </a>
                    </div>
                    <div className="relative lg:w-1/2 lg:flex flex-col lg:flex-row items-center justify-center hidden">
                        <img src="./wretched.jpg" className="h-[300px]"/>
                    </div>
                </div>
            </div>
            
        </div>
        
    )
}