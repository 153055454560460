import { Component, useEffect, useState } from 'react';
import classNames from 'classnames';



const navBarClass = classNames({
    'right-0 rounded-bl-xl fixed right-0 z-20 p-4 bg-zinc-950': true
});

const linkClass = classNames({
    'text-white hover:text-red-700 duration-300 cursor-pointer text-4xl font-bold font-Cinzel' : true
})

const ruleClass = classNames({
    'w-2/3 h-[2px] bg-white my-2' : true
})

export default class Navbar extends Component {

    state = {
        open : false,
    };

    toggleMenu = () => {
        this.setState({open: !this.state.open})
    }

    render() {
        
        this.barClass1 = classNames({
            'absolute w-full h-[10%] bg-white duration-500 rounded-full top-0 left-0': true,
            'rotate-45 top-[45%]' : this.state.open
        });

        this.barClass2 = classNames({
            'absolute w-full h-[10%] bg-white duration-500 rounded-full top-[45%] left-0': true,
            'opacity-0 -left-[100%]' : this.state.open
        });

        this.barClass3 = classNames({
            'absolute w-full h-[10%] bg-white duration-500 rounded-full bottom-0 left-0': true,
            '-rotate-45 bottom-[45%]' : this.state.open
        });

        this.sideNavClass = classNames({
            'w-screen lg:w-1/3 h-screen bg-zinc-950 fixed z-10 flex flex-col items-center justify-center duration-500' : true,
            'right-0' : this.state.open,
            '-right-full' : !this.state.open
        });

        return(
            <>
                <div className={navBarClass}>
                    <div></div>
                    <button className="h-8 w-10 relative " onClick={this.toggleMenu}>
                        <span className={this.barClass1}/>
                        <span className={this.barClass2}/>
                        <span className={this.barClass3}/>
                    </button>
                </div>
                <ul className={this.sideNavClass}>
                    <li className={linkClass}><a href='#bio'>BIO</a></li>
                    <hr className={ruleClass}/>
                    <li className={linkClass}><a href='#merch'>MERCH</a></li>
                    <hr className={ruleClass}/>
                    <li className={linkClass}><a href='#gigs'>GIGS</a></li>
                    <hr className={ruleClass}/>
                    <li className={linkClass}><a href='#wretched'>WRETCHED EP</a></li>
                    <hr className={ruleClass}/>
                    <li className={linkClass}><a>EPK</a></li>
                    <hr className={ruleClass}/>
                    <li className={linkClass}><a>GET IN TOUCH</a></li>
                </ul>
            </>
            
        )
        
    };

}